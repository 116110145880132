import React, { useEffect } from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Slider from "react-slick"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Layout from "../components/landingLayout"
import IlustraEspecialistas from '../images/especialidade-about.png'
import IlustraTheia from '../images/theia-about.png'
import IlustraPlano from '../images/plano-about.png'
import ImgPlanejandoGravidez from '../images/planejando-gravidez.png'
import Techcrunch from '../images/techcrunch.png'
import Draft from '../images/draft.png'
import Exame from '../images/exame.png'
import Aspas from '../images/aspas.png'
import { Divider } from "../components/atoms/lines/Divider"
import Prev from '../images/prev.png'
import Next from '../images/next.png'
import Maite from '../images/img_maite.png'
import Nat from '../images/img_nathalia.png'

import './index.scss'
import './landing.scss'
import trackEvent from "../analyticsHelpers"
import Localization from "../components/localization"
import { LandingForm } from "../components/landingForm"

declare global { interface Window { analytics: any; } }

function LandingPlanejandoGravidez() {
  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <img src={Prev} onClick={onClick} id="prev" className="control prev block absolute top-0 z-50" alt="botão prev" />
    );
  }
  
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <img src={Next} onClick={onClick} id="prev" className="control next block absolute top-0 z-50 right-0" alt="botão next" />
    );
  }
  
  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  useEffect(() => {
    trackEvent("sitenovo-landing-planejando-gravidez-visualizou-site")
  }, [])

  return (
    <Layout isWhiteLogo>
      <GatsbySeo
        title='Planejando a gravidez'
        description='A Theia tem uma filosofia de parto humanizado pensada na melhor experiência para você'
        language='pt-br'
        canonical='https://blog.theia.com.br/planejando-gravidez'
        openGraph={{
          type: 'website',
          url: 'https://blog.theia.com.br/planejando-gravidez',
          title: 'Planejando a gravidez',
          description: 'A Theia tem uma filosofia de parto humanizado pensada na melhor experiência para você',
          images: [
            {
              url: 'https://theia.com.br/theia.png',
              width: 250,
              height: 367,
              alt: 'Og Imagem Alt',
            },
          ],
        }}
        metaTags={[{
          name: 'dc:title',
          content: 'Planejando a gravidez'
        }, {
          name: 'dc:description',
          content: 'A Theia tem uma filosofia de parto humanizado pensada na melhor experiência para você'
        }, {
          name: 'dc:language',
          content: 'BR',
        }, {
          name: 'reply-to',
          content: 'suporte@theia.com.br',
        }, {
          name: 'author',
          content: 'Theia',
        }, {
          name: 'description',
          content: 'A Theia tem uma filosofia de parto humanizado pensada na melhor experiência para você',
        }, {
          name: 'keywords',
          content: 'gravidez, sintomas de gravidez, testes de gravidez, ginecologistas, obstetras, pré-natal, pre natal, semanas de gestacao, ensaio gestante, exame de gravidez, mulher gravida, ginecologistas unimed,semanas gestacionais, gravidez ectopica, consulta pre natal, consulta pré natal, parto, períneo, parto normal, parto humanizado, puerpério, cesaria, cesariana, parto cesaria, parto cesária',
        },
        , {
          property: 'twitter:card',
          content: 'summary_large_image',
        }
      ]}
      />
      <section className="landing-planejando-gravidez">
        <div className="md:flex md:flex-col equipe-first-section-parto-theia">
          <h1 className="text-titleMedium lg:text-titleExtraLarge font-medium text-mediumPurpleColor">
            Planejando a gravidez
          </h1>
          <div className="mb-8 flex lg:flex-row flex-col justify-center planejando-main-content">
            <div className="flex flex-col planejando-content planejando-left-content">
             <img src={ImgPlanejandoGravidez} alt="mulher com teste de gravidez na mão" />
            </div>
            <div className="flex flex-col planejando-content planejando-right-content">
             <h2>05/05 às 19h00</h2>
             <p className="text-white right-paragraph mt-6">Um papo virtual com </p>
             <div className="flex w-full">
               <img src={Maite} alt="Dr Maite" />
               <p className="text-white right-especialidade">Dra Maitê Covas <span className="block">(Obstetra especialista em reprodução humana)</span>
              </p>
             </div>
             <div className="flex mt-8 w-full">
               <img src={Nat} alt="Natalia Duarte" />
               <p className="text-white right-especialidade">Nathalia Duarte <span className="block">(Doula)</span>
              </p>
             </div>
            </div>
          </div>
          <LandingForm formName="landing planejando gravidez" className="form-left landing-gravidez" buttonBgColor="bg-mediumPurpleColor" footerWrapperClass="footer-wrapper-section-row pre-footer-form landing-gravidez" newLabel="Inscreva-se">
            <p className="text-center text-titleLarge text-white mb-8">Preencha o formulário para participar</p>
          </LandingForm>
          
          </div>
      </section>

      <section className="about-theia landing-about-theia wrapper-section-row bg-white text-center items-center lg:items-start">
        <h2 className="item1 text-titleMedium lg:text-titleLarge font-medium text-darkPurpleColor main-title">
          Redefinimos a experiência do cuidado na gravidez com mais apoio, respeito e autoconfiança
        </h2>
        <div className="item2 flex flex-1 text-darkPurpleColor flex-col items-center text-center lg:text-titleMedium text-textSmall font-light">
          <span><img src={IlustraEspecialistas} alt="ilustração especialistas" /></span>
          <p>Equipe multidisciplinar com obstetra, nutricionista, fisioterapeuta e mais</p>
          <a href="/especialistas" className="lg:text-textSmall text-textExtraSmall underline mt-6 lg:mt-12" onClick={() => trackEvent("sitenovo-landing-planejando-gravidez-clicou-conheca-especialidades")}>CONHEÇA NOSSAS ESPECIALIDADES</a>
        </div>
        <div className="item3 flex flex-1  flex-col text-darkPurpleColor items-center text-center lg:text-titleMedium text-textSmall font-light">
          <span><img src={IlustraPlano} alt="ilustração planos" /></span>
          <p>Disponível no consultório ou no app, todos os dias</p>
          <a href="/servicos" className="lg:text-textSmall text-textExtraSmall underline mt-6 lg:mt-12" onClick={() => trackEvent("sitenovo-landing-planejando-gravidez-clicou-veja-serviços")}>VEJA NOSSOS SERVIÇOS</a>
        </div>
        <div className="item4 flex flex-1 text-darkPurpleColor flex-col items-center text-center lg:text-titleMedium text-textSmall font-light">
          <span><img src={IlustraTheia} alt="ilustração theia" /></span>
          <p>Respeito às suas escolhas, da concepção ao parto</p>
          <a href="/quem-somos" className="lg:text-textSmall text-textExtraSmall underline mt-6 lg:mt-12" onClick={() => trackEvent("sitenovo-landing-planejando-gravidez-clicou-saiba-mais-theia")}>SAIBA MAIS SOBRE A THEIA</a>
        </div>
        <div className="item5">
          <AnchorLink
            stripHash={true} 
            onAnchorLinkClick={() =>  trackEvent("sitenovo-landing-planejando-gravidez-clicou-queroconversar")}
            className="want-landing bg-mediumPurpleColor"
            to="/planejando-gravidez/#pre-footer"
            >
             Quero conversar com alguém da Theia
          </AnchorLink>
        </div>
      </section>

      <section className="specialists-theia text-center landing-saude-specialists wrapper-section-row lg:items-start">
        
        <h2 className="item1 text-titleMedium lg:text-titleLarge font-medium text-mediumPurpleColor main-title">
          Profissionais experientes, <br/>cuidando de você de forma completa
        </h2>
        <div className="contents lg:hidden item2">
        <Slider {...settings} className="overflow-hidden">
          <div>
            <div className="flex flex-col items-center text-center">
              <span><img src="https://theia.com.br/images/graziela.png" alt="Graziela Verdade" className="specialist-image" /></span>
              <p className="text-textSmall">Graziela Verdade</p>
              <p className="text-textSmaller font-light">Consultora de amamentação</p>
            </div>
          </div>
          <div>
            <div className="flex flex-col items-center text-center">
              <span><img src="https://theia.com.br/images/michele-melao.png" alt="Michele Melão" className="specialist-image" /></span>
              <p className="text-textSmall">Michele Melão</p>
              <p className="text-textSmaller font-light">Consultora de sono</p>
            </div>
          </div> 
          <div>
            <div className="flex flex-col items-center text-center">
              <span><img src="https://theia.com.br/images/juliana-goldman.png" alt="Juliana Goldman" className="specialist-image" /></span>
              <p className="text-textSmall">Juliana Goldman</p>
              <p className="text-textSmaller font-light">Dentista</p>
            </div>
          </div> 
          <div>
            <div className="flex flex-col items-center text-center">
              <span><img src="https://theia.com.br/images/karine-zamberlan.png" alt="Karine Zamberlan" className="specialist-image" /></span>
              <p className="text-textSmall">Karine Zamberlan</p>
              <p className="text-textSmaller font-light">Fisio pélvica</p>
            </div>
          </div> 
          <div>
            <div className="flex flex-col items-center text-center">
              <span><img src="https://theia.com.br/images/laura-penteado.png" alt="Laura penteado" className="specialist-image" /></span>
              <p className="text-textSmall">Laura penteado</p>
              <p className="text-textSmaller font-light">Gineco-obstetra</p>
            </div>
          </div> 
          <div>
            <div className="flex flex-col items-center text-center">
              <span><img src="https://theia.com.br/images/lais-hess.png" alt="Laís Hess" className="specialist-image" /></span>
              <p className="text-textSmall">Laís Hess</p>
              <p className="text-textSmaller font-light">Nutricionista</p>
            </div>
          </div> 
          <div>
            <div className="flex flex-col items-center text-center">
              <span><img src="https://theia.com.br/images/fernanda-misumi.png" alt="Fernanda Misum" className="specialist-image" /></span>
              <p className="text-textSmall">Fernanda Misumi </p>
              <p className="text-textSmaller font-light">Pediatra</p>
            </div>
          </div> 
          <div>
            <div className="flex flex-col items-center text-center">
              <span><img src="https://theia.com.br/images/felipe-da-silva.png" alt="Felipe da Silva" className="specialist-image" /></span>
              <p className="text-textSmall">Felipe da Silva</p>
              <p className="text-textSmaller font-light">Preparador físico</p>
            </div>
          </div>
          <div>
            <div className="flex flex-col items-center text-center">
              <span><img src="https://theia.com.br/images/sandra-quero.png" alt="Sandra Quero" className="specialist-image" /></span>
              <p className="text-textSmall">Sandra Quero</p>
              <p className="text-textSmaller font-light">Psicóloga</p>
            </div>
          </div>
        </Slider>
        </div>
        <ul className="item2 hidden lg:flex lg:flex-row items-center lg:items-start text-darkGreyColor">
          <li className="flex flex-col flex-1 items-center text-center">
            <span><img src="https://theia.com.br/images/graziela.png" alt="Graziela Verdade" className="specialist-image" /></span>
            <p className="text-textSmall">Graziela Verdade</p>
            <p className="text-textSmaller font-light">Consultora de amamentação</p>
          </li>
          <li className="flex flex-col flex-1 items-center text-center">
            <span><img src="https://theia.com.br/images/michele-melao.png" alt="Michele Melão" className="specialist-image" /></span>
            <p className="text-textSmall">Michele Melão </p>
            <p className="text-textSmaller font-light">Consultora de sono</p>
          </li>
          <li className="flex flex-col flex-1 items-center text-center">
            <span><img src="https://theia.com.br/images/juliana-goldman.png" alt="Juliana Goldman" className="specialist-image" /></span>
            <p className="text-textSmall">Juliana Goldman</p>
            <p className="text-textSmaller font-light">Dentista</p>
          </li>
          <li className="flex flex-col flex-1 items-center text-center">
            <span><img src="https://theia.com.br/images/karine-zamberlan.png" alt="Karine Zamberlan" className="specialist-image" /></span>
            <p className="text-textSmall">Karine Zamberlan</p>
            <p className="text-textSmaller font-light">Fisio pélvica</p>
          </li>
          <li className="flex flex-col flex-1 items-center text-center">
            <span><img src="https://theia.com.br/images/laura-penteado.png" alt="Laura penteado" className="specialist-image" /></span>
            <p className="text-textSmall">Laura penteado</p>
            <p className="text-textSmaller font-light">Gineco-obstetra</p>
          </li>
          <li className="flex flex-col flex-1 items-center text-center">
            <span><img src="https://theia.com.br/images/lais-hess.png" alt="Laís Hess" className="specialist-image" /></span>
            <p className="text-textSmall">Laís Hess </p>
            <p className="text-textSmaller font-light">Nutricionista</p>
          </li>
          <li className="flex flex-col flex-1 items-center text-center">
            <span><img src="https://theia.com.br/images/fernanda-misumi.png" alt="Fernanda Misumi" className="specialist-image" /></span>
            <p className="text-textSmall">Fernanda Misumi</p>
            <p className="text-textSmaller font-light">Pediatra</p>
          </li>
          <li className="flex flex-col flex-1 items-center text-center">
            <span><img src="https://theia.com.br/images/felipe-da-silva.png" alt="Felipe da Silva" className="specialist-image" /></span>
            <p className="text-textSmall">Felipe da Silva</p>
            <p className="text-textSmaller font-light">Preparador físico</p>
          </li>
          <li className="flex flex-col flex-1 items-center text-center">
            <span><img src="https://theia.com.br/images/sandra-quero.png" alt="Sandra Quero" className="specialist-image" /></span>
            <p className="text-textSmall">Sandra Quero</p>
            <p className="text-textSmaller font-light">Psicóloga</p>
          </li>
        </ul>
        <div className="item3 lg:mt-10">
          <a href="/especialistas" className="lg:text-textSmall text-textExtraSmall underline text-darkPurpleColor" onClick={() => trackEvent("sitenovo-landing-planejando-gravidez-clicou-conheca-nosso-time-profissionais")}>CONHEÇA TODO NOSSO TIME DE PROFISSIONAIS</a>
          <AnchorLink
            stripHash={true} 
            onAnchorLinkClick={() =>  trackEvent("sitenovo-landing-planejando-gravidez-clicou-queroconversar")}
            className="want-landing bg-mediumPurpleColor"
            to="/planejando-gravidez/#pre-footer"
            >
             Quero conversar com alguém da Theia
          </AnchorLink>
        </div>
      </section>

      <Localization eventName="sitenovo-landing-planejando-gravidez-mapa-clicou-mapa" />

      <section className="media-theia wrapper-section-row text-center items-center lg:items-start">
        
        <h2 className="item1 text-titleMedium lg:text-titleLarge font-medium text-darkPinkColor text-center">
          Theia na mídia
        </h2>
        <div className="item2 flex flex-col text-left mb-10 lb:mb-0">
          <span className="media-icon"><img src={Exame} alt="exame image" /></span>
            <p className="text-media text-textSmall text-drakGreyColor font-light">
              <span>
                <img src={Aspas} alt="aspas" />
              </span>
              Flávia Deutsch e Paula Crespi vivenciaram o mundo das startups e criaram um site que liga mães e pais aos conselhos de profissionais especializados.
            </p>
            <Divider
              maxWidth="52px"
              border="1px solid #ED1D53"
              className="mt-6 mb-2"
            />
            <p className="text-darkPinkColor textSmaller">Out/2019</p>
            <a href="https://exame.com/pme/maes-empreendedoras-captam-r-7-mi-para-site-que-tira-duvidas-dos-pais/" target="_blank" className="text-textSmall text-mediumPinkColor mt-4 underline lg:text-center">LER MATÉRIA COMPLETA</a>
        </div>
        <div className="item3 flex flex-col text-left">
          <span className="media-icon media-icon-second"><img src={Draft} alt="draft image" /></span>
          <p className="text-media text-textSmall text-drakGreyColor font-light">
            <span>
              <img src={Aspas} alt="aspas" />
            </span>
            A healthtech se posiciona como a primeira plataforma digital a oferecer a mães e pais o acesso a uma rede de profissionais especializados como pediatras, psicólogos, ginecologistas, nutricionistas, coaching de carreira, terapeuta do sono, aleitamento materno etc.
          </p>
          <Divider
            maxWidth="52px"
            border="1px solid #ED1D53"
            className="ml-auto lg:mx-0 mt-6 mb-2"
          />
          <p className="ml-auto lg:mx-0 text-darkPinkColor textSmaller">Ago/2020</p>
          <a href="https://www.projetodraft.com/conciliar-filhos-e-trabalho-esta-dificil-que-tal-uma-rede-de-apoio-24h/" target="_blank" className="text-textSmall text-mediumPinkColor mt-4 underline lg:text-center ml-auto lg:mx-0">LER MATÉRIA COMPLETA</a>
        </div>
        <div className="item4 flex flex-col hidden lg:flex text-left">
          <span className="media-icon"><img src={Techcrunch} alt="techcrunch image" /></span>
            <p className="text-media text-textSmall text-drakGreyColor font-light">
              <span>
                <img src={Aspas} alt="aspas" />
              </span>
              Theia, which ended the year as the largest all-female founded company raise in Latin America.
            </p>
            <Divider
              maxWidth="52px"
              border="1px solid #ED1D53"
              className="mt-6 mb-2"
            />
            <p className="text-darkPinkColor textSmaller">Fev/2020</p>
          <a href="https://techcrunch.com/2020/02/06/latin-america-takes-the-global-lead-in-vc-directed-to-female-co-founders/" target="_blank" className="text-textSmall text-mediumPinkColor mt-4 underline lg:text-center">LER MATÉRIA COMPLETA</a>
        </div>

        <div className="item4 flex flex-col hidden lg:flex text-left">
          <span className="media-icon"><img src={Techcrunch} alt="techcrunch image" /></span>
            <p className="text-media text-textSmall text-drakGreyColor font-light">
              <span>
                <img src={Aspas} alt="aspas" />
              </span>
              Theia, which ended the year as the largest all-female founded company raise in Latin America.
            </p>
            <Divider
              maxWidth="52px"
              border="1px solid #ED1D53"
              className="mt-6 mb-2"
            />
            <p className="text-darkPinkColor textSmaller">Fev/2020</p>
          <a href="https://techcrunch.com/2020/02/06/latin-america-takes-the-global-lead-in-vc-directed-to-female-co-founders/" target="_blank" className="text-textSmall text-mediumPinkColor mt-4 underline lg:text-center">LER MATÉRIA COMPLETA</a>
        </div>
        <div className="item5 mt-6 lg:mt-10">
          <a href="/midia" className="lg:text-textSmall text-textExtraSmall underline text-darkPinkColor" onClick={() => trackEvent("sitenovo-landing-planejando-gravidez-clicou-veja-mais-midia")}>VEJA MAIS</a>
          <AnchorLink
            stripHash={true} 
            onAnchorLinkClick={() =>  trackEvent("sitenovo-landing-planejando-gravidez-clicou-queroconversar")}
            className="want-landing bg-darkPinkColor"
            to="/planejando-gravidez/#pre-footer"
            >
             Quero conversar com alguém da Theia
          </AnchorLink>
        </div>
      </section>
    </Layout>
  )
}

export default LandingPlanejandoGravidez
